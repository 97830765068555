.waf-main {
  height: 600px;
}

.waf-row {
  height: calc(100% - 28px);
}

.top-container-main {
  height: 600px;
}

.top-container-main-row {
  height: calc(100% - 28px);
}

.top-container-main-row-scanner {
  height: calc(100% - 62px);
}

.custom-pagination {
  align-items: center;
  margin-bottom: 0px;
  --bs-pagination-border-width: 0px;
  --bs-pagination-disabled-bg: none;
  --bs-pagination-bg: none;
  --bs-pagination-active-bg: #398ffe;
  --bs-pagination-hover-bg: #398efe00;
  --bs-pagination-focus-box-shadow: none;

  .page-item.active {
    border-radius: 5px !important;
    border: 1px solid transparent;
    overflow: hidden;
  }

  .page-item {
    &:hover a {
      //     border-radius: 5px !important;
      // border: 1px solid transparent;
      // overflow: hidden;
    }
  }

  .page-link {
    color: #f9fafb;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-page-text {
    font-size: 14px;
    font-weight: 400;
    color: #fff9f9;
  }
}

.select-page {
  --bs-btn-border-color: #017df900;
  --bs-btn-hover-border-color: #1a8afa00;
  background-color: #39393c !important;
  width: 54px;
  height: 32px;
  padding: 3px;
  font-size: 14px;

  &:hover,
  &:focus {
    color: white !important;
  }
}

.dropdown-menu {
  --bs-dropdown-min-width: 4rem;
}

//
.ports-list-table {
  background: rgba(39, 39, 40, 1);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 17px;

  .borts-table-border {
    border-bottom: 1px solid #636363;
  }

  .divider {
    background: white;
    width: 2px;
    height: 16px;
    margin-right: 10px;
  }

  .port-table-heading {
    color: white;
    font-weight: 500;
    padding-bottom: 22px;
  }

  .left-ports-container {
    cursor: pointer;

    svg {
      cursor: pointer;
    }

    .arrow-rotated-up {
      transition: transform 0.3s ease-in-out;
      transform: rotate(-90deg);
      width: 19px;
    }

    .arrow-rotated-down {
      transition: transform 0.3s ease-in-out;
      transform: rotate(0);
      width: 19px;
    }

    span {
      padding: 0px 10px;
      color: #f5f5f5;
    }
  }

  .right-ports-container {
    .total-ports-details {
      display: flex;
      align-items: center;

      .divider {
        background: white;
        width: 2px;
        height: 16px;
        margin-right: 10px;
      }

      .detail-text {
        color: white;
        font-weight: 100;

        span {
          font-weight: 700;
        }
      }
    }

    .port-collapse-table {
      margin-left: 35px;

      table {
        width: 100%;

        th {
          width: 16.666%;
          color: white;
          font-weight: 500;
          padding-bottom: 22px;
        }

        td {
          width: 16.666%;
          color: #ffffffd9;
          font-weight: 100;
          padding-left: 15px;
          padding-bottom: 12px;
          padding-top: 10px;
        }
      }
    }
  }
}

.port-sort-container {
  padding: 0px 0px 15px 14px;
  color: white;

  svg {
    cursor: pointer;
    margin-left: 7px;
  }
}

.stack-chart-canva {
  canvas {
    height: 215px !important;
    margin-top: -3px !important;
  }
}

// domain list table

.no-data {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: white;
}

.domain-list-table {
  background: rgba(39, 39, 40, 1);
  border-radius: 4px;
  padding: 16px;
  align-items: flex-start;
  margin-bottom: 17px;

  .hostname-link-blank {
    background-color: #525253;
    color: #fff;
    padding: 1px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    width: fit-content;
  }

  .hostname-link {
    background-color: #525253;
    color: #ffffff;
    padding: 1px 12px;
    border-radius: 4px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    text-decoration: underline;
  }

  .hostname-inactive {
    background-color: #525253;
    color: #ffffff;
    padding: 1px 12px;
    border-radius: 4px;
    font-size: 16px;
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
  }

  .badge {
    background-color: #525253;
    color: white;
    border-radius: 4px;
    font-size: 0.8em;
    margin-left: 5px;
  }

  .hostname-link svg {
    width: 12px;
    height: 12px;
  }

  .additional-hostnames {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }

  .view-more-main {
    padding: 10px 0 0 0px;
  }

  .view-more {
    color: #398ffe;
    cursor: pointer;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 700;
  }

  .total-domain-details {
    display: flex;
    align-items: center;

    .divider {
      margin: 0 13px;
      background: white;
      width: 2px;
      height: 16px;
    }

    .detail-text {
      color: white;
      font-weight: 100;

      span {
        font-weight: 700;
      }
    }
  }

  .left-domain-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: width 0.3s ease;

    svg {
      cursor: pointer;
    }

    .arrow-rotated-up {
      margin-right: 10px;
      transition: transform 0.3s ease-in-out;
      transform: rotate(-90deg);
      width: 19px;
    }

    .arrow-rotated-down {
      margin-right: 10px;
      transition: transform 0.3s ease-in-out;
      transform: rotate(0);
      width: 19px;
    }

    span {
      color: white;
    }
  }

  .right-domain-container {
    transition: width 0.3s ease;

    .domain-collapse-table {
      width: 100%;
      margin-top: 20px;
      margin-left: 10px;

      .status-text {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      table {
        width: 100%;
        border-collapse: collapse;

        th {
          color: white;
          font-weight: 500;
          padding-bottom: 22px;
          padding-left: 0px;
          text-align: left;
        }

        td {
          color: #ffffffd9;
          font-weight: 100;
          padding-left: 0px !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);

          .badge {
            background-color: gray;
            color: white;
            padding: 2px 5px;
            border-radius: 4px;
            font-size: 0.8em;
            margin-left: 5px;
          }
        }

        .domain-badge {
          font-size: smaller;
          display: inline-block;
          background: #444;
          border-radius: 4px;
          padding: 1px 5px;
          margin-right: 5px;
          cursor: pointer;
        }

        .active {
          color: #3ddc97;
        }

        .validating {
          color: #ffffff;
        }

        .inactive {
          path {
            fill: #8c8c91 !important;
          }

          color: #8c8c91;
        }
      }
    }
  }
}

.expand-header-text {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.port-box {
  padding: 10px 15px;
  font-size: 15px !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: #333333 !important;

  span {
    font-size: larger;
    font-weight: bolder;
    margin-right: 5px;
  }
}

.coming-tool-view {
  font-size: 15px;
  font-weight: 900;
  line-height: 22px;
  text-align: center;
  color: #398ffe;
  border-top: 1px solid #d9d9d9;
  padding: 20px;
  cursor: pointer;
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  bottom: 0;
  z-index: 1000;
  background-color: #fff;
}

.custom-table #no-padding {
  padding: 0px !important;
}

.data-leaks-badge {
  font-size: 14px;
  --bs-bg-opacity: 1;
  background-color: #525253 !important;
  color: var(--white);
}

.data-leaks-info-icon {
  fill: white !important;
  mix-blend-mode: color-dodge;
}

.data-leaks-table {
  .sub-table-container {
    overflow: auto;
    margin-bottom: 20px;

    .custom-table {
      // width: 1850px !important;
    }

    .table-responsive {
      position: relative;
    }

    .table-responsive::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .table-responsive::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 10px;
    }

    .table-responsive::-webkit-scrollbar:hover {
      width: 2px;
    }

    .table-responsive::-webkit-scrollbar {
      height: 5px;
    }
  }
}

.sensitive-exposed-data-list-table {
  background: rgba(39, 39, 40, 1);
  border-radius: 4px;
  padding: 0 16px 0px 16px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 17px;

  .data-table-border {
    border-bottom: 1px solid #636363;
  }

  .divider {
    background: white;
    width: 2px;
    height: 16px;
    margin-right: 10px;
  }

  .data-table-heading {
    color: white;
    font-weight: 500;
    padding-bottom: 22px;
  }

  .left-data-container {
    cursor: pointer;
    padding-top: 16px;
    display: flex;
    align-items: flex-start;

    svg {
      cursor: pointer;
      position: relative;
      top: 7px;
      width: 27px;
    }

    .arrow-rotated-up {
      transition: transform 0.3s ease-in-out;
      transform: rotate(-90deg);
      width: 19px;
    }

    .arrow-rotated-down {
      transition: transform 0.3s ease-in-out;
      transform: rotate(0);
      width: 19px;
    }

    span {
      padding: 0px 10px;
      color: #f5f5f5;
      font-size: 17px;
    }
  }

  .right-data-container {
    .total-data-details {
      display: flex;
      align-items: center;
      padding-top: 16px;
      padding-bottom: 16px;

      .divider {
        background: white;
        width: 2px;
        height: 16px;
        margin-right: 10px;
      }

      .detail-text {
        color: white;
        font-weight: 100;
        align-items: center;

        svg {
          margin: 0px 8px 3px 0px;
        }

        span {
          font-weight: 700;
        }
      }
    }

    .data-collapse-table {
      table {
        width: 100%;

        th {
          color: white;
          font-weight: 500;
          padding-bottom: 22px;
          padding-top: 12px;
        }

        .button-data {
          color: #398ffe;
          font-weight: 700;
          font-size: 12px;
          cursor: pointer;
          padding-right: 0;
          text-align: center;
          max-width: 421px;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          position: sticky;
          right: 0;
          background-color: #272728;
          padding: 0px;

          div {
            width: 100%;
            height: 100%;
            padding-left: 15px;
            padding-right: 15px;
            height: 7vh;
            display: flex;
            align-items: center;
          }
        }

        td {
          color: #ffffffd9;
          font-weight: 100;
          padding-right: 50px;
          padding-bottom: 10px;
          padding-top: 12px;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .sensitive-collapse-table-container {
      overflow: auto;
    }

    .data-collapse-table {
      position: relative;
    }

    .sensitive-collapse-table-container::-webkit-scrollbar-track {
      background-color: #464646;
      height: 30px;
    }

    .sensitive-collapse-table-container::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      height: 5px;
      border-radius: 5px;
      cursor: pointer;
    }

    .sensitive-collapse-table-container::-webkit-scrollbar:hover {
      width: 2px;
      cursor: pointer;
    }

    .sensitive-collapse-table-container::-webkit-scrollbar {
      height: 5px;
      cursor: pointer;
    }
  }
}

.sensitive-exposed-data-sort-container {
  padding: 0px 0px 15px 14px;
  color: white;

  svg {
    cursor: pointer;
    margin-left: 7px;
  }
}

.sidebar-header {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header-upper-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .header-title {
      font-weight: 400;
      font-size: 20px;
    }
  }

  .header-lower-block {
    span {
      font-size: 14px;
      color: white;

      .hostname-link {
        font-size: 14px;
      }
    }
  }
}

.popup-hr-main {
  border: 2px solid #cbcbcc;
  height: 2px;
  margin-top: -18px;
}

.sidebar-body {
  padding: 0px 20px;

  .title {
    p {
      border: 1px solid #636363 !important;
      margin: 0;
      border: 1px;
      border-radius: 4px;
      padding: 20px;
      color: #bcf4de;
      background-color: #353537;
      // word-break: break-all;
      word-break: keep-all;
      overflow-wrap: normal;
    }
  }

  .popup-dropdown {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    margin: 36px 0 28px;

    h3 {
      margin: 0;
    }
  }

  .popup-dropdown {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    margin: 36px 0 28px;

    h3 {
      margin: 0;
    }

    .base-drop-down-arrow {
      path {
        cursor: pointer;
        fill: #fff !important;
      }
    }

    .base-drop-down-arrow {
      transition: transform 0.3s ease-in-out;
      transform: rotate(-90deg);
    }

    .base-drop-down-arrow.open {
      transform: rotate(0);
    }
  }
}

.base-drop-down-arrow {
  path {
    cursor: pointer;
    fill: #fff !important;
  }
}

.base-drop-down-arrow {
  transition: transform 0.3s ease-in-out;
  transform: rotate(-90deg);
}

.base-drop-down-arrow.open {
  transform: rotate(0);
}

.sensitive-icons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sensitive-icons-sub {
  position: absolute;
  top: 0px;
}

.sensitive-data-cards {
  overflow-x: auto;

  div {
    padding-left: 5px;
    padding-right: 5px;
  }

  .warning-icon {
    background-color: #3c3c3e;
    width: max-content;
    height: fit-content;
    display: flex;
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    top: 9px;
    right: 7px;
    border: 1px solid #747476;
  }

  .sensitive-data-card-text {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    margin-top: 30px;
    padding: 0 !important;
  }

  .sensitive-data-card-value {
    font-size: 20px;
    font-weight: 700;
  }

  .card {
    height: 150px !important;

    .card-body {
      div {
        margin-bottom: 0 !important;
        text-align: center !important;
      }
    }
  }
}

.sensitive-data-cards::-webkit-scrollbar-track {
  background-color: transparent;
}

.sensitive-data-cards::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
}

.sensitive-data-cards::-webkit-scrollbar:hover {
  width: 2px;
}

.sensitive-data-cards::-webkit-scrollbar {
  width: 5px;
}

.domain-badge-custom {
  font-size: smaller;
  display: inline-block;
  background: #444;
  border-radius: 4px;
  padding: 1px 5px;
  margin-right: 5px;
  cursor: pointer;
}

.domain-badge-custom-scanner {
  font-size: smaller;
  display: inline-block;
  background: #444;
  border-radius: 4px;
  padding: 1px 5px;
  margin-right: 5px;
  cursor: pointer;
}

.scanner-card-generic {
  padding: 0 !important;
}

.border-left {
  div {
    border-left: 2px solid grey;
  }
}

.data-table-border {
  position: relative;
}

.sensitive-exposed-empty {
  .card {
    height: 100% !important;
  }

  .no-data-container {
    padding: 0px;

    .no-data-text {
      padding: 0px;
      margin-top: 15px;
    }
  }
}

.cve-list-table {
  background: rgba(39, 39, 40, 1);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 17px;

  .borts-table-border {
    border-bottom: 1px solid #636363;
  }

  .divider {
    background: white;
    width: 2px;
    height: 16px;
    margin-right: 10px;
  }

  .port-table-heading {
    color: white;
    font-weight: 500;
    padding-bottom: 22px;
  }

  .left-ports-container {
    cursor: pointer;

    svg {
      cursor: pointer;
    }

    .arrow-rotated-up {
      transition: transform 0.3s ease-in-out;
      transform: rotate(-90deg);
      width: 19px;
    }

    .arrow-rotated-down {
      transition: transform 0.3s ease-in-out;
      transform: rotate(0);
      width: 19px;
    }

    span {
      padding: 0px 10px;
      color: #f5f5f5;
    }
  }

  .right-ports-container {
    .total-ports-details {
      display: flex;
      align-items: center;

      .divider {
        background: white;
        width: 2px;
        height: 16px;
        margin-right: 10px;
      }

      .detail-text {
        color: white;
        font-weight: 100;

        span {
          font-weight: 700;
        }
      }
    }

    .port-collapse-table {
      margin-left: 35px;

      table {
        width: 100%;

        th {
          width: 16.666%;
          color: white;
          font-weight: 500;
          padding-bottom: 22px;
        }

        td {
          width: 16.666%;
          color: #ffffffd9;
          font-weight: 100;
          padding-left: 15px;
          padding-bottom: 12px;
          padding-top: 10px;
        }
      }
    }
  }
}