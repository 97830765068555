.Cves-popup-header {
    padding: 10px 2px 5px 2px;

    .sub-title {
        font-size: 14px;
        background: rgba(99, 99, 99, 1);
        border-radius: 4px;
        color: #ffffff;
        padding: 1px 5px;
    }

    .cvss-score {
        font-size: 12px;
        font-weight: 400;
        color: rgba(229, 229, 229, 1);
    }

    .percentage {
        margin-right: 55px;
        font-size: 24px;
        font-weight: 400;
        color: rgba(255, 97, 85, 1);
    }

    .close-icon {
        cursor: pointer;
        width: 15px;
        height: 20px;
        top: 0px;
        right: 0px;
        position: absolute;
    }

    h2,
    h4,
    h5 {
        margin: 0;
    }

    h3 {
        margin-top: -10px;
        margin-bottom: 0 !important;

    }

}

.popup-hr-main {
    border: 2px solid #cbcbcc;
    height: 2px;
    margin-top: -18px
}


.Cves-popup-main {
    padding: 0px 20px;

    .risk {
        border: 1px solid #636363 !important;
        margin: 0;
        border: 1px;
        border-radius: 4px;
        color: #ffffff;
        background-color: #353537;

        .risk-first-div {
            padding: 20px;
            background-position: right;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .risk-second-div {
            padding: 20px 20px 20px 0px;
            width: 29%;
            display: flex;
            align-items: center;
            justify-content: center !important;
        }

        .score {
            margin-left: 12px;
            position: relative;

            p {
                margin: 0;
            }

            span {
                font-size: 24px;
                font-weight: 700;
                color: rgba(255, 97, 85, 1);
            }
        }
    }

    .ip-port {
        border: 1px solid #636363 !important;
        margin: 0;
        border: 1px;
        border-radius: 4px;
        padding: 20px;
        color: #bcf4de;
        background-color: #353537;

        .text {
            font-size: 16px;
            font-weight: 400;

        }

        .link {
            color: #93c2ff;
            cursor: pointer;
        }

        hr {
            margin: 0;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    .summary {
        p {
            border: 1px solid #636363 !important;
            margin: 0;
            border: 1px;
            border-radius: 4px;
            padding: 20px;
            color: #bcf4de;
            background-color: #353537;
            // word-break: break-all;
            word-break: keep-all;
            overflow-wrap: normal;
        }

    }

    .generic-text-link a {
        text-decoration: underline;
    }

    .popup-dropdown {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 15px;
        cursor: pointer;
        margin: 36px 0 28px;

        h3 {
            margin: 0;
        }

        .base-drop-down-arrow {
            path {
                cursor: pointer;
                fill: #fff !important;
            }
        }

        .base-drop-down-arrow {
            transition: transform 0.3s ease-in-out;
            transform: rotate(-90deg);
        }

        .base-drop-down-arrow.open {
            transform: rotate(0);
        }
    }

    .popup-export-button {
        border: none;
        padding: 8px 20px;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        .popup-export-icon {
            margin-right: 8px;
            fill: #398ffe;
        }

        .popup-export-text {
            font-size: 1.25rem;
            color: #398ffe;
            font-weight: 700;
        }

        .btn-icon {
            width: 5%;
            background: none;
            border: none;
            color: inherit;
            padding: 0;
        }

        .btn-icon:hover {
            background: none;
        }
    }

    .popup-hr {
        border: 2px solid #cbcbcc;
        height: 2px;
        margin-top: 26px;
    }

    .popup-cisa {
        border: 2px solid white;
        border-radius: 6px;
        padding: 2px 10px;
        font-size: 14px;
    }


}

.offcanvas-backdrop.show {
    background-color: rgba(128, 128, 128, 0.5) !important;
    backdrop-filter: blur(8px) !important;
    opacity: 0.7 !important;
}

.data-leaks-cve {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
}


.scanner-canvas{
    background: #1D1D21 !important ;
    
}

.cve-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    max-width: 100%;
    margin-left: 0.5rem;
  }
  
  .cve-link {
    background-color: #636363;
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    border: 1px solid #636363;
  }
  
  .cve-link-margin {
    margin-bottom: 8px;
  }
  
  .cve-link-icon {
    width: 18px;
    height: 18px;
    margin-left: 0.5rem;
  }
  
  .cve-remaining {
    background-color: #636363;
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    border: 1px solid #636363;
    cursor: pointer;
    position: relative;
  }
  
  .cve-popover {
    background-color: #1E1E1E;
    max-width: none;
  }
  
  .cve-remaining-item {
    text-decoration: none;
    color: #fff;
  }
  
  .cve-remaining-content {
    display: flex;
    align-items: center;
  }
  
  .cve-remaining-bullet {
    margin-right: 0.5rem;
  }
  
  .cve-remaining-text {
    color: #000000;
  }
  
  .cve-remaining-icon {
    width: 12px;
    height: 12px;
    margin-left: 0.5rem;
  }