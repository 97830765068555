//SecurityIssueCard
$primary-color: rgba(255, 97, 85, 1);
$secondary-color: rgba(243, 167, 56, 1);
$tertiary-color: rgba(61, 220, 151, 1);

.cyberservicesCard_Main {
  border-radius: 4px;
}

.cyberservicesCard_submain {
  background-image: linear-gradient(4.67deg,
      rgba(90, 90, 91, 0) -25.21%,
      #5a5a5b 130.89%);
  width: 100%;
  margin-top: 1.5rem;
  color: #f5f5f5;
  padding: 15px 20px;
  border: 1px solid #636363;
  border-radius: 4px;

  .cyberservice_container {
    padding-right: 24px;

    .cyberservice_title {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 12px;
      font-size: 20px;
      font-weight: 600 !important;
      padding-top: 5px;
    }

    .cyber_container_col {
      .cyberservice_header_title {
        font-size: 13px;
        font-weight: 100;
        margin-bottom: 7px;
      }

      .cyberservice_header_text {
        font-size: 16px;

        a {
          color: #398ffe;
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }

    .risk_score_container {
      display: flex;
      flex-direction: column;
      align-items: end;

      .cyberservice_header_text {
        margin-top: -11px !important;
      }
    }
  }
}

.cyberservicesCard_section_1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  flex-direction: column;
  width: 39%;
  font-weight: 400;

  .section_1_part1 {
    display: flex;
    align-items: center;
    justify-content: center;

    .cyberservicesCard_title {
      font-size: clamp(1rem, 2.5vw + 1rem, 1.3rem);
    }

    .section_1_part1_subpart {
      margin-left: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .securityissueCard_subtitle {
        color: #398ffe;
        font-weight: 700;
        margin-left: 0.5rem;
      }
    }
  }

  .cyberservicessection_1_part2 {
    font-size: 1rem;
  }

  .cyberservicessection_1_part3 a {
    font-size: 1rem;
    color: #398ffe;
    font-weight: 700;
  }

  .section_1_part2 {
    font-size: 0.75rem;
  }

  .section_1_part4 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;

    .section_1_part4_subpart {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 2px 8px 2px 8px;
      border-radius: 4px;
    }
  }

  .section_1_part5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.75rem;
    width: 100%;

    .section_1_part5_subpart1 {
      display: flex;
      align-items: center;
      justify-content: center;

      .section_1_part5_subpart1_sub {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.2);
        padding: 2px 8px 2px 8px;
        border-radius: 4px;
        margin-left: 0.7rem;
      }
    }
  }
}

.cyberservicesCard_section_2 {
  .one {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding-right: 5rem;
    margin-top: 3rem;
    width: 10%;
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;

    .cyberservicessection_1_part2 {
      font-size: 1rem;
      color: #e5e5e5;
    }

    .cyberservicessection_1_part3 {
      font-size: 1rem;
      font-weight: 700;
    }

    .securityissueCard_section_2_part1 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      height: 3rem;

      .securityissueCard_section_2_part1_text {
        font-size: 0.875rem;
      }
    }

    .securityissueCard_section_2_part2 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      height: 6rem;

      .securityissueCard_section_2_part2_value {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
}

.cyberservicesCard_section_2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding-right: 5rem;
  margin-top: 3rem;
  width: 25%;
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;

  .cyberservicessection_1_part2 {
    font-size: 1rem;
    color: #e5e5e5;
  }

  .cyberservicessection_1_part3 {
    font-size: 1rem;
    font-weight: 700;
  }

  .securityissueCard_section_2_part1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 3rem;

    .securityissueCard_section_2_part1_text {
      font-size: 0.875rem;
    }
  }

  .securityissueCard_section_2_part2 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 6rem;

    .securityissueCard_section_2_part2_value {
      font-size: 1rem;
      font-weight: 700;
    }
  }
}

.securityissueCard_section_3 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  // width: 9.5rem;
  color: #B1B1B1;
  // margin-top: 3rem;

  .securityissueCard_section_3_text {
    font-size: 0.75rem;
  }
}

.securityissue-section_3 {
  .securityissueCard_subtitle {
    color: #398ffe;
    font-weight: 700;
    margin-left: 0.3rem;
    // width: 150px;
    font-size: 12px;
  }
}

.securityissueCard_menu {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.securityissueCard_section_3_value_primary {
  font-size: 24px;
  color: $primary-color;
}

.securityissueCard_section_3_value_secondary {
  font-size: 24px;
  color: $secondary-color;
}

.securityissueCard_section_3_value_tertiary {
  font-size: 24px;
  color: $tertiary-color;
}

.hr_line {
  border-bottom: 1px solid #636363;
  width: 95%;
  height: 10px;
  position: absolute;
  top: 3rem;
}

.text-size {
  font-size: 16px !important;
  font-weight: 700 !important;
  width: 25rem !important;
}

.contact-us-main {
  width: 50%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  margin: auto;
  margin-top: 15px;
  padding: 30px 24px 24px;
  background: #1b1b1c;
  border-radius: 4px;

  .contact-us-sent-container {
    width: 80%;
    margin: auto;

    .contact-us-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        width: 100px;
      }

      .contact-submit-logo {
        margin-top: 24px;
      }

      span {
        color: rgba(255, 255, 255, 0.9);
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0px 0px 5px;
      }

      div {
        color: rgba(255, 255, 255, 0.9);
        font-size: 16px;
        margin-bottom: 5px;
        text-align: center;
      }
    }
  }

  .contact-us-container {
    width: 70%;
    margin: auto;

    .contact-us-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        width: 100px;
      }

      span {
        color: rgba(255, 255, 255, 0.9);
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 26px 0px 5px;
      }

      div {
        color: rgba(255, 255, 255, 0.9);
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    .contact-us-form {
      label {
        color: #b8b8b8;
        margin-top: 20px;
      }

      .form-control {
        margin: 5px 0px 0px 0px;
      }

      .form-control:not(.is-invalid) {
        border-color: white !important;
      }

      .form-control::placeholder {
        color: #6f6f6f !important;
        font-size: 16px;
      }
    }
  }
}

.coming-soon-container {
  color: #398ffe;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  padding: 30px 0px 0px;
}

.add-target-main {
  width: 80%;
  margin: auto;
  padding: 0px 24px 24px;
  border-radius: 4px;

  .add-target-container {
    width: 80%;
    margin: auto;

    .add-target-form {
      .default-text {
        display: flex;
        align-items: center;
        margin-top: 50px !important;
      }

      h1 {
        font-weight: 600;
      }

      .sub-heading {
        font-weight: 400;
        font-weight: 16px;
      }

      .tag-default {
        padding: 2px 10px 3px;
        color: white;
        border-radius: 5px;
        background: #398ffe;
        font-size: 11px;
        font-weight: 200;
        position: relative;
        top: -2px;
        left: 10px;
      }

      .checkbox-scan {
        .form-check-input[type="checkbox"] {
          border-radius: 0.25em;
          margin-top: 25px;
          gap: 9px;
        }
      }

      .box {
        background: #373737;
        padding: 10px;
        border-radius: 4px;
        display: flex;
        align-items: start;
        justify-content: center;

        .box-text {
          color: white;
          font-size: 16px;
          font-weight: 400;
          display: contents;
        }
      }

      hr {
        margin-top: 30px !important;
        margin-bottom: -20px !important;
        border: 1px solid #454545;
      }

      label {
        color: #b8b8b8;
        margin-top: 20px;
      }

      .form-control {
        margin: 5px 0px 0px 0px;
      }

      .form-control:not(.is-invalid) {
        border-color: white !important;
      }

      .form-control::placeholder {
        color: #6f6f6f !important;
        font-size: 16px;
      }
    }
  }
}

// registration
.register-fragment {
  margin-bottom: 150px;

  .register-main {
    background: #1b1b1c;
    padding: 16px 24px 24px;
    border-radius: 4px;
    // margin: auto;
    margin-top: 150px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    .password-requirements li {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }

    .password-requirements li svg {
      margin-right: 8px;
    }

    .text-success {
      color: green;
    }

    .text-danger {
      color: red;
    }

    .register-form {
      width: 664px;
      margin: auto;

      .register-input {
        width: 70%;
        margin: auto;
      }

      .form-control {
        margin: 5px 0px 0px 0px;
      }

      .form-control:not(.is-invalid) {
        border-color: white !important;
      }

      .form-control::placeholder {
        color: #6f6f6f !important;
        font-size: 16px;
      }

      .form-select {
        height: 50px !important;
        background: #1b1b1c !important;
        border-radius: 6px !important;
        border: 2px solid white;
      }
    }
  }
}

// login
.login-fragment {
  margin-bottom: 150px;

  .login-main {
    margin-top: 150px;
    background: #1b1b1c;
    padding: 16px 24px 24px;
    border-radius: 4px;
    // margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.3);

    .login-form {
      width: 664px;
      margin: auto;

      .login-input {
        width: 70%;
        margin: auto;
      }

      .form-control {
        margin: 5px 0px 0px 0px;
      }

      .form-control:not(.is-invalid) {
        border-color: white !important;
      }

      .form-control::placeholder {
        color: #6f6f6f !important;
        font-size: 16px;
      }
    }
  }
}

// forgot
.forgot-fragment {
  margin-bottom: 210px;

  .forgot-main {
    margin-top: 215px;
    background: #1b1b1c;
    padding: 16px 24px 24px;
    border-radius: 4px;
    // margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.3);

    .cancel {
      color: rgba(57, 143, 254, 1);
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
    }

    .forgot-form {
      width: 664px;
      margin: auto;

      .forgot-input {
        width: 70%;
        margin: auto;
      }

      .form-control {
        margin: 5px 0px 0px 0px;
      }

      .form-control:not(.is-invalid) {
        border-color: white !important;
      }

      .form-control::placeholder {
        color: #6f6f6f !important;
        font-size: 16px;
      }
    }
  }
}

// reset password
.reset-fragment {
  margin-bottom: 80px;

  .reset-main {
    margin-top: 80px;
    background: #1b1b1c;
    padding: 16px 24px 24px;
    border-radius: 4px;
    // margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.3);

    .password-requirements li {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }

    .password-requirements li svg {
      margin-right: 8px;
    }

    .text-success {
      color: green;
    }

    .text-danger {
      color: red;
    }

    .cancel {
      color: rgba(57, 143, 254, 1);
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
    }

    .btn-disabled {
      background-color: grey;
      color: white;
    }

    .reset-form {
      width: 664px;
      margin: auto;

      .reset-input {
        width: 70%;
        margin: auto;
      }

      .form-control {
        margin: 5px 0px 0px 0px;
        height: 50px !important;
        background: #1b1b1c !important;
        border-radius: 6px !important;
        border: 2px solid white;
      }

      .form-control:not(.is-invalid) {
        border-color: white !important;
      }

      .form-control::placeholder {
        color: #6f6f6f !important;
        font-size: 16px;
      }
    }
  }
}

.label-for-chip {
  margin-top: 0px !important;
  color: inherit !important;
}

.scan-content-text {
  color: #b8b8b8;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .no-data-icon {
    margin-bottom: 11px;
  }
}

.data-in-process {
  background: rgba(39, 39, 40, 1);
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 4px;
}

.ciphers-cell {
  display: flex;
  align-items: center;
  min-width: 190px;

  span {
    margin-left: 10px;
  }
}


.copy-icon {
  cursor: pointer;
  margin-left: 5px;
  color: #6c757d;
  // width: 16px;
  // height: 17px;
}

.copy-icon:hover {
  color: #007bff;
}

.text-box {
  background: #373737;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: start;

  .box-text {
    color: white;
    font-size: 16px;
    font-weight: 400;
    display: contents;
  }
}

.license-container {
  border: 1px solid #FFFFFF;
  margin: auto;
  padding: 50px 24px;
  background: #272728;
  border-radius: 4px;

}

.license-units {
  font-size: 50px;
  font-weight: 400;
  width: 100%;
}

.license-no-limit {
  font-size: 30px;
  font-weight: 400;
  width: 100%;
}

.lincense-bar {
  height: 21px;
  width: 388px;
}

.lincense-content {
  font-size: 12px !important;
  cursor: pointer;
}

.utilization-container {
  padding-bottom: 33px;

  .toast {
    --bs-toast-max-width: 860px;
    --bs-toast-header-bg: rgba(255, 0, 0, 0) !important;
    --bs-toast-header-color: rgba(255, 0, 0, 0) !important;
    display: block !important;

    .btn-close {
      display: none;
    }
  }
}

.fw-600 {
  font-weight: 600;
}

.contatct-text {
  color: #398FFE;

}




.warning-banner-bg {
  .banner {
    background: #FEF4EA;
    padding: 10px;
    border-radius: 4px;

  }

  .banner-title {
    color: #3B3B3E !important;
  }

}

.info-banner-bg {
  .banner {
    background: #EEF5FF;
    padding: 10px;
    border-radius: 4px;
  }

  .banner-title {
    color: #3B3B3E !important;
  }

}

.error-banner-bg {
  .banner {
    background: #FFD6D6;
    padding: 10px;
    border-radius: 4px;
  }

  .banner-title {
    color: #3B3B3E !important;
  }


}

.licnese-box {
  height: 195px;
}

// Sticky table styles for horizontal scrolling
.port-collapse-table-container {
  position: relative;
  overflow-x: auto;
  width: 100%;
  
  // Webkit scrollbar styling (Chrome, Safari, newer Edge)
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px;
    background-color: #464646;
    display: block !important;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 4px;
    min-width: 40px;
  }
  
  // Remove all scrollbar buttons/arrows
  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    display: none;
    width: 0;
    height: 0;
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-corner {
    display: none;
    background: transparent;
  }
  
  // Force horizontal scroll only
  &:horizontal {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

// Firefox scrollbar styling
@supports (scrollbar-color: #FFFFFF #464646) {
  .port-collapse-table-container {
    scrollbar-width: thin;
    scrollbar-color: #FFFFFF #464646;
    overflow: -moz-scrollbars-horizontal;
    scrollbar-width: thin !important;
  }
}

.sticky-table {
  width: 100%;
  // table-layout: fixed;
  // border-collapse: separate;
}

.sticky-column {
  position: sticky;
  z-index: 1;
  background: rgb(39, 39, 40);
}

.sticky-column-port {
  left: 0;
}

.sticky-column-cve-indication {
  left: 80px;
}

.sticky-column-title {
  left: 200px;
}

.sticky-header {
  z-index: 2;
}

.column-title-cell {
  white-space: normal;
  word-break: break-word;
}

.column-width-port {
  min-width: 80px !important;
  max-width: 100px !important;
  width: 100px !important;
}

.column-width-cve-indication {
  min-width: 120px !important;
  max-width: 150px !important;
  width: 150px !important;
}

.column-width-title {
  min-width: 250px !important;
  max-width: 250px !important;
  width: 250px !important;
}

.column-width-cvss {
  min-width: 150px !important;
  max-width: 150px !important;
  width: 150px !important;
}

.column-width-cisa {
  min-width: 150px !important;
  max-width: 150px !important;
  width: 150px !important;
}

.column-width-risk {
  min-width: 150px !important;
  max-width: 150px !important;
  width: 150px !important;
}

.column-width-cve-numbers {
  // min-width: 650px !important;
  // max-width: 650px !important;
  // width: 650px !important;
}

.column-width-first-detected {
  min-width: 150px !important;
  max-width: 150px !important;
  width: 150px !important;
}

.column-width-last-updated {
  min-width: 150px !important;
  max-width: 150px !important;
  width: 150px !important;
}

.column-width-current-state {
  min-width: 150px !important;
  max-width: 150px !important;
  width: 150px !important;
}



.report-card-container {
  border: 1px solid #D5D8DC;
  margin: auto;
  padding: 50px 24px;
  background: #272728;
  border-radius: 4px;

}
.report-title {
font-size: 20px;
font-weight: 700;
line-height: 22px;
text-align: left;
color: #FFFFFF;
}

.download-link {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  color: #398FFE;
}

.report-details {
  padding: 0;
  margin: 0;
  color: #FFFFFF;  
}

.report-details li {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #FFFFFF;  
  margin-bottom: 0.5rem;
}

.last-downloaded {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #FFFFFF;
  color: #bbb;
}

.report-header-line{
  border: 1px solid #FFFFFF80;
}

.report-not-download{
  color: #979797;
}
